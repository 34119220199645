import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { MatRipple } from '@angular/material/core';
import { SafeHtml } from '@angular/platform-browser';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'scp-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgStyle, RouterLink, MatRipple],
})
export class ListItemComponent {

  @Input()
  public url: string;

  @Input()
  public urlInfo: string;

  @Input()
  public title: string;

  @Input()
  public description: string | SafeHtml;

  @Input()
  public imgSrc: string;

  @Input()
  public hasArrow = true;

  @Input()
  public locked = false;

  @Input()
  public highlighted = false;

  constructor(private readonly router: Router) {
  }

  public async goToInfo(): Promise<void> {
    await this.router.navigateByUrl(this.urlInfo);
  }

}
