<div class='scp-list-item'>
  <a matRipple
     [routerLink]='url'
     [title]='title'
     class='scp-list-item__link'
     [class.scp-list-item__link__highlighted]='highlighted'
     [class.hover]='url'
     [matRippleDisabled]='!url'
     [ngStyle]="{ 'pointer-events': locked ? 'none' : 'all' }">
    @if (locked) {
      <div class='scp-list-item__lock'></div>
      <div class='scp-list-item__icon'></div>
    }
    <div [class.scp-list-item__img]='imgSrc' class='scp-list-item__logo'>
      @if (imgSrc) {
        <img [src]='imgSrc' alt='' loading='lazy' height='41' width='41' />
      }
    </div>
    <div class='scp-list-item__info'>
      <div class='scp-list-item__title'>
        <span>{{ title }}</span>
        <p [innerHtml]='description'></p>
      </div>
      @if (hasArrow) {
        <img src='/assets/images/svg/arrow.svg' alt='' loading='lazy' width='8' height='12' />
      }
    </div>
  </a>
  @if (urlInfo) {
    <div matRipple
         class='scp-list-item__btn'
         (click)='goToInfo()'
         [matRippleDisabled]='!urlInfo'>
      <img src='/assets/images/svg/info-dark.svg' alt='' loading='lazy' height='24' width='24' />
    </div>
  }
</div>
